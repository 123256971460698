import React, { useEffect, useState } from "react";
import styles from "./dashboard.module.scss";
import GaugeBar from "../components/UI/GaugeBar/GaugeBar";
import { useParams } from "react-router-dom";
import Legend from "../components/UI/Legend/Legend";
import WavesGroup from "../components/UI/WavesGroup/WavesGroup";
import TrendlineChartContainer from "../components/UI/TrendlineChartContainer/TrendlineChartContainer";
import {
  useGetReportDataByIdQuery,
  useGetReportsByUserIdQuery,
} from "../services/reports";
import { Spinner } from "../components/UI/Spinner/Spinner";
import RecommendationSection from "../components/UI/RecommendationSection/RecommendationSection";

export function Dashboard() {
  const params = useParams();
  const [userNotAvailable, setUserNotAvailable] = useState(true);
  const {
    data: dashboardData,
    isError: dashboardDataIsError,
    isFetching: dashboardDataIsFetching,
    isSuccess: dashboardDataIsSuccess,
    error: dashboardDataError,
  } = useGetReportDataByIdQuery(params.id);
  const { isFetching: reportHistoryIsFetching } = useGetReportsByUserIdQuery(
    dashboardData?.reportData?.userIdentifier,
    {
      skip: userNotAvailable,
    }
  );

  useEffect(() => {
    if (dashboardDataIsSuccess) {
      setUserNotAvailable(false);
    }
  }, [dashboardDataIsSuccess]);

  const renderDashboard = ({ charts, reportData, recommendations }) => {
    const createdOnDate = new Date(reportData.reportingDate).toLocaleString(
      "en-US",
      {
        day: "2-digit",
        month: "2-digit",
        year: "2-digit",
        timeZone: "UTC",
      }
    );

    return (
      <div className={styles["dashboard-container"]}>
        <div className={styles["inner-container"]}>
          <div className={styles["title-container"]}>
            <span className={`${styles.title}`}>tallywell score</span>
          </div>
          <div className={styles["user-data"]}>
            <div className={styles["left-side"]}>
              <div className={styles.info}>
                <span className={styles.header}>gender:</span>
                <span className={styles.text}>
                  {reportData.userProfileGender}
                </span>
              </div>
              <div className={styles.info}>
                <span className={styles.header}>age:</span>
                <span className={styles.text}>{reportData.userProfileAge}</span>
              </div>
            </div>
            <div className={styles["right-side"]}>
              <div className={styles.info}>
                <span className={styles.header}>weight:</span>
                <span className={styles.text}>
                  {reportData.userProfileWeight}
                </span>
              </div>
              <div className={styles.info}>
                <span className={styles.header}>height:</span>
                <span className={styles.text}>
                  {reportData.userProfileHeight}
                </span>
              </div>
            </div>
          </div>
          <div className={styles["progress-container"]}>
            <WavesGroup
              heartRateScoreValue={reportData.heartRateScoreValue}
              heartRateScoreLabel={reportData.heartRateScoreLabel}
              stepsScoreValue={reportData.stepsScoreValue}
              stepsScoreLabel={reportData.stepsScoreLabel}
              sleepScoreValue={reportData.sleepScoreValue}
              sleepScoreLabel={reportData.sleepScoreLabel}
              bmiScoreValue={reportData.bmiScoreValue}
              bmiScoreLabel={reportData.bmiScoreLabel}
              mirrorEffect={true}
            />
            <div className={styles["gauge-container"]}>
              <GaugeBar
                gaugeProgress={reportData.scoreLabel}
                gaugeScore={reportData.scoreValue}
              />
            </div>
            <WavesGroup
              heartRateScoreValue={reportData.heartRateScoreValue}
              heartRateScoreLabel={reportData.heartRateScoreLabel}
              stepsScoreValue={reportData.stepsScoreValue}
              stepsScoreLabel={reportData.stepsScoreLabel}
              sleepScoreValue={reportData.sleepScoreValue}
              sleepScoreLabel={reportData.sleepScoreLabel}
              bmiScoreValue={reportData.bmiScoreValue}
              bmiScoreLabel={reportData.bmiScoreLabel}
              mirrorEffect={false}
            />
          </div>
          <div className={styles["created-on-date"]}>As of {createdOnDate}</div>
          <div className={styles["progress-legend-container"]}>
            <Legend />
          </div>
          {recommendations.length > 0 &&
            recommendations?.map((recommendation) => {
              return (
                <React.Fragment key={recommendation.id}>
                  {recommendation.description && (
                    <div className={styles.recommendation}>
                      <RecommendationSection recommendation={recommendation} />
                    </div>
                  )}
                </React.Fragment>
              );
            })}

          {charts.length > 0 &&
            charts?.map((chart) => {
              return <TrendlineChartContainer key={chart.id} chart={chart} />;
            })}
        </div>
      </div>
    );
  };

  return (
    <>
      {dashboardDataIsSuccess && renderDashboard(dashboardData)}
      {dashboardDataIsError && (
        <div className="error" style={{ color: "white" }}>
          {dashboardDataError?.data?.title}
        </div>
      )}
      {(dashboardDataIsFetching || reportHistoryIsFetching) && <Spinner />}
    </>
  );
}

export default Dashboard;
