import { createSlice } from "@reduxjs/toolkit";
import { extendedApiService } from "../services/reports";

const initialState = {
  status: "idle",
  error: null
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  extraReducers: (builder) => {
    builder
      .addMatcher(
        extendedApiService.endpoints.getReportDataById.matchPending,
        (state) => {
          state.status = "pending";
        }
      )
      .addMatcher(
        extendedApiService.endpoints.getReportDataById.matchFulfilled,
        (state) => {
          state.status = "succeeded";
        }
      )
      .addMatcher(
        extendedApiService.endpoints.getReportDataById.matchRejected,
        (state) => {
          state.status = "error";
        }
      );
  },
});

// Action creators are generated for each case reducer function
export default dashboardSlice.reducer;
