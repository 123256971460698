import styles from "./MajorIndicators.module.scss";
import ToggleChartBtn from "../ToggleChartBtn/ToggleChartBtn";

export default function MajorIndicators(props) {
  return (
    <div className={styles.indicators}>
      <div className={styles["left-side-data"]}>
        <span className={`${styles["indicators-label"]}`}>
          {props?.chart?.label}
        </span>
        <span
          className={`${styles.range} ${
            props.hasProgressValue
              ? styles.progressValue
              : styles.noProgressValue
          }`}
        >
          {props?.chart?.progressValue}
        </span>
      </div>
      <div>
        <ToggleChartBtn
          onClick={props.onClick}
          id={`${props.chart.id}-container`}
          chartVisible={!props.state}
        />
      </div>
      <div className={styles["right-side-data"]}>
        <span className={styles.avgLabel}>{props?.chart?.avgLabel}</span>
        {!props.rightSideValue && (
          <span
            className={`${styles.range} ${styles.averageValue}`}
          >{`${props?.chart?.averageValue} ${props?.chart?.value}`}</span>
        )}
        {props.rightSideValue && (
          <span
            className={`${styles.range} ${styles.averageValue}`}
          >{`${props?.rightSideValue}`}</span>
        )}
      </div>
    </div>
  );
}
