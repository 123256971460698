import styles from "./RecommendationSection.module.scss";

export default function RecommendationSection({ recommendation }) {
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>{recommendation.title}</h1>
      <p className={styles.description}>{recommendation.description}</p>
    </div>
  );
}
