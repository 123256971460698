import "./ToggleChartIcons.scss";

export default function HideChartIcon() {
  return (
    <div className="chart-icon hide-icon">
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 3.75C4.4775 3.75 0 10 0 10C0 10 4.4775 16.25 10 16.25C15.5225 16.25 20 10 20 10C20 10 15.5225 3.75 10 3.75ZM10 13.75C7.92875 13.75 6.25 12.0712 6.25 10C6.25 7.92875 7.92875 6.25 10 6.25C12.0712 6.25 13.75 7.92875 13.75 10C13.75 12.0712 12.0712 13.75 10 13.75Z"
          fill="#535353"
        />
      </svg>
    </div>
  );
}
