import "./Wave.scss";

export default function WaveDown(props) {
  const pathClasses = "wave " + props.className;

  return (
    <div
      className="wave-down"
    >
      <svg
        viewBox="0 0 54 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className={pathClasses}
          d="M0.973481 2.18692C2.1915 0.469061 4.56996 0.0649522 6.28592 1.28432C12.7072 5.84725 18.5546 8.37105 26.6856 8.37105C34.8165 8.37105 40.664 5.84725 47.0852 1.28432C48.8012 0.0649522 51.1796 0.469061 52.3976 2.18692C53.6157 3.90478 53.212 6.28587 51.496 7.50523C44.1763 12.7066 36.8165 15.9998 26.6856 15.9998C16.5546 15.9998 9.19476 12.7066 1.87508 7.50523C0.159127 6.28587 -0.24454 3.90478 0.973481 2.18692Z"
          fill="#E0E0E0"
        />
      </svg>
    </div>
  );
}
