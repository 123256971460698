import { useRef } from "react";
import { useReducer } from "react";
import { useLayoutEffect } from "react";
import { useState } from "react";
import styles from "./Tooltip.module.scss";

const handleTooltipHeight = (state, action) => {
  if (action.type === "LAYOUT_RENDERED") {
    return { value: action.value };
  }
  return {
    value: state.value,
  };
};

export default function Tooltip({ content, children }) {
  const [positionY, setPositionY] = useState("top");

  const contentRef = useRef(null);
  const [tooltipHeightState, dispatchTooltipHeight] = useReducer(
    handleTooltipHeight,
    { value: 0 }
  );

  useLayoutEffect(() => {
    dispatchTooltipHeight({
      type: "LAYOUT_RENDERED",
      value: contentRef.current.clientHeight,
    });
  }, []);

  function mouseOverHandler(e) {
    let rect = e.target.getBoundingClientRect();
    let positionY = rect.y > tooltipHeightState.value + 100 ? "top" : "bottom";
    setPositionY(positionY);
    // the function form is used when the next state depends on the previous state of the same state
    // here positionY depends on another state tooltipHeightState
  }

  return (
    <div onMouseOver={mouseOverHandler} className={`${styles.tooltip}`}>
      {children}
      <div
        ref={contentRef}
        className={`${styles.tooltiptext} ${styles[`tooltip-${positionY}`]}`}
      >
        {content}
      </div>
    </div>
  );
}
