import { createSlice } from "@reduxjs/toolkit";
import { extendedApiService } from "../services/reports";

const initialState = {
  isOpened: false,
  historyData: [],
};

export const sidenavSlice = createSlice({
  name: "sidenav",
  initialState,
  reducers: {
    openSidenav: (state) => {
      state.isOpened = true;
    },
    closeSidenav: (state) => {
      state.isOpened = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        extendedApiService.endpoints.getReportsByUserId.matchPending,
        (state) => {
          state.status = "pending";
        }
      )
      .addMatcher(
        extendedApiService.endpoints.getReportsByUserId.matchFulfilled,
        (state, action) => {
          state.status = "succeeded";

          state.historyData = [...action.payload.historyData];
        }
      )
      .addMatcher(
        extendedApiService.endpoints.getReportsByUserId.matchRejected,
        (state) => {
          state.status = "error";
        }
      );
  },
});

export const { openSidenav, closeSidenav } = sidenavSlice.actions;

export default sidenavSlice.reducer;
