import "./GaugeBar.scss";

export default function GaugeBar(props) {
  let progress =
    props.gaugeProgress === "needs work" ? "needs-work" : props.gaugeProgress;

  function getIsIOS() {
    let os = navigator.userAgent;
    if (os.search("Mac") !== -1) {
      return true;
    }

    return false;
  }

  return (
    <div className="gauge-bar">
      <svg
        viewBox="0 0 475 241"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={`progress-${progress}`}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.00009 240.5C1.34323 240.5 9.16702e-05 239.157 9.1815e-05 237.5C0.000100887 133.728 66.5522 45.5077 159.294 13.1779C160.858 12.6326 162.569 13.4587 163.114 15.0232C163.66 16.5878 162.833 18.2982 161.269 18.8436C70.8625 50.3592 6.0001 136.359 6.00009 237.5C6.00009 239.157 4.65695 240.5 3.00009 240.5Z"
          fill="#535353"
          id="line-60"
          className="progress"
        />
        <path
          d="M166.906 13.792C166.429 12.2054 167.328 10.5322 168.915 10.0549C201.432 0.271846 236.015 -2.39376 269.652 2.15845C271.294 2.38066 272.445 3.8918 272.222 5.53369C272 7.17557 270.489 8.32645 268.847 8.10425C236.052 3.66588 202.333 6.26657 170.643 15.8005C169.057 16.2778 167.384 15.3786 166.906 13.792Z"
          fill="#535353"
          id="line-92"
          className="progress"
        />
        <path
          d="M373.701 46.563C372.755 47.9232 370.886 48.259 369.526 47.313C342.771 28.7047 311.906 15.5991 278.568 9.63121C276.937 9.33925 275.851 7.78044 276.143 6.14952C276.435 4.51859 277.994 3.43314 279.625 3.72509C313.835 9.84902 345.504 23.2974 372.951 42.3872C374.312 43.3332 374.647 45.2028 373.701 46.563Z"
          fill="#535353"
          id="line-120"
          className="progress"
        />
        <path
          d="M445.572 129.205C444.113 129.99 442.294 129.444 441.509 127.984C425.744 98.6796 403.848 73.1595 377.537 53.1425C376.218 52.1393 375.963 50.2571 376.966 48.9385C377.969 47.6199 379.851 47.3642 381.17 48.3673C408.158 68.8995 430.619 95.077 446.793 125.142C447.578 126.601 447.031 128.42 445.572 129.205Z"
          fill="#535353"
          id="line-147"
          className="progress"
        />
        <path
          d="M472 240.5C470.343 240.5 469 239.157 469 237.5C469 201.39 460.735 167.219 445.995 136.768C445.273 135.277 445.897 133.483 447.388 132.761C448.88 132.039 450.674 132.663 451.396 134.154C466.521 165.403 475 200.465 475 237.5C475 239.157 473.657 240.5 472 240.5Z"
          fill="#535353"
          id="line-180"
          className="progress"
        />
      </svg>
      <div id="rotator" className={`rotator-${progress} ${getIsIOS() ? 'IOSPosition' : 'OtherOSPosition'}`}>
        <div id="marker" className={`marker-progress-${progress}`}></div>
      </div>
      <div className="scores-container">
        <div className="scores">{props.gaugeScore}</div>
        <div className={`scores-progress ${progress}`}>{progress}</div>
      </div>
    </div>
  );
}
