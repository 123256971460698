import React from "react";
import { createPortal } from "react-dom";
import "./Spinner.scss";

const spinnerContainer = document.getElementById("loader-container");

export const Spinner = () => {
  return (
    <>
      {createPortal(
        <div className="global-loading">
          <div className="loader"></div>
        </div>,
        spinnerContainer
      )}
    </>
  );
};
