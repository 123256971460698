import "./CloseMenuIcon.scss";

export function CloseMenuIcon(props) {
  function closeMenuHandler() {
    props.onClick();
  }

  return (
    <div onClick={closeMenuHandler} className="close-icon">
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 23 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.76631 16.695C3.26421 16.1929 3.23514 15.4079 3.70138 14.9417L17.7714 0.871709C18.2376 0.405471 19.0226 0.434543 19.5247 0.936646C20.0268 1.43875 20.0559 2.22375 19.5896 2.68998L5.51965 16.76C5.05341 17.2262 4.26842 17.1971 3.76631 16.695Z"
          fill="#E0E0E0"
        />
        <path
          d="M3.94804 0.695026C4.45014 0.192923 5.23514 0.163849 5.70138 0.630088L19.7714 14.7001C20.2376 15.1663 20.2085 15.9513 19.7064 16.4534C19.2043 16.9555 18.4193 16.9846 17.9531 16.5183L3.8831 2.44836C3.41686 1.98212 3.44594 1.19713 3.94804 0.695026Z"
          fill="white"
        />
      </svg>
    </div>
  );
}
