import WaveDown from "./WaveDown";
import WaveUp from "./WaveUp";
import "./WaveLine.scss";
import { useEffect, useState } from "react";
import { useCallback } from "react";

export default function WaveLine(props) {
  const waveScore = props.waveScore;
  const hasMirrorEffect = props.mirrorEffect;
  const waveProgress = props.waveProgress;

  const [scores, setScores] = useState(0);

  const determineScores = useCallback(() => {
    switch (waveProgress) {
      case "poor":
        waveScore < 300 ? setScores(0) : setScores(580);
        break;
      case "needs work":
        setScores(670);
        break;
      case "fair":
        setScores(740);
        break;
      case "good":
        setScores(800);
        break;
      case "excellent":
        setScores(850);
        break;
      default:
        setScores(0);
        break;
    }
  }, [waveProgress, waveScore]);

  useEffect(() => {
    determineScores();
  }, [determineScores]);

  return (
    <div className={"waveline " + (hasMirrorEffect ? "rotateWave" : "")}>
      <WaveUp className={`animation-delay-pink-1 wave-pink-${scores}`} />
      <WaveDown
        className={`animation-delay-pink-2 wave-pink-${
          waveScore < 440 ? 0 : scores
        }`}
      />
      <WaveUp className={`animation-delay-violet-1 wave-violet-${scores}`} />
      <WaveDown
        className={`animation-delay-violet-2 wave-violet-${
          waveScore < 625 ? 0 : scores
        }`}
      />
      <WaveUp className={`animation-delay-yellow-1 wave-yellow-${scores}`} />
      <WaveDown
        className={`animation-delay-yellow-2 wave-yellow-${
          waveScore < 705 ? 0 : scores
        }`}
      />
      <WaveUp className={`animation-delay-cian-1 wave-cian-${scores}`} />
      <WaveDown
        className={`animation-delay-cian-2 wave-cian-${
          waveScore < 770 ? 0 : scores
        }`}
      />
      <WaveUp className={`animation-delay-green wave-green-${scores}`} />
    </div>
  );
}
