import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { store } from "./app/store/store";
import { Provider } from "react-redux";
import { registerLicense } from "@syncfusion/ej2-base";

// Registering Syncfusion license key
registerLicense(
  "Mgo+DSMBaFt+QHFqVkNrXVNbdV5dVGpAd0N3RGlcdlR1fUUmHVdTRHRcQlliSH5VdkNmWX9bcXA=;Mgo+DSMBPh8sVXJ1S0d+X1RPd11dXmJWd1p/THNYflR1fV9DaUwxOX1dQl9gSXpSd0VlWXlbcHRTT2Q=;ORg4AjUWIQA/Gnt2VFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5Xd0RjX39YdXRVTmRY;MTY4MzY3MkAzMjMxMmUzMTJlMzMzNUNWeDFoVnBxWWdsUHZwT3l0aHAxRE5BK1F1Q2FzZ0lUZ1JzQlE1bk9WRms9;MTY4MzY3M0AzMjMxMmUzMTJlMzMzNVh4YXBlejhnUVlrOHQ5WUk0ajVsbFNTKzUyZ083WmZiVFFvTCtSYTBWT2c9;NRAiBiAaIQQuGjN/V0d+XU9Hc1RDX3xKf0x/TGpQb19xflBPallYVBYiSV9jS31TckRiWXlednFVR2ZeVQ==;MTY4MzY3NUAzMjMxMmUzMTJlMzMzNWk5dnlSNnNZcGduQUt1azg1aGFBNUhQc3ZOMmpZMWJ3Tloydm8zTFpIWkk9;MTY4MzY3NkAzMjMxMmUzMTJlMzMzNW93cUQ1dHNlOEEzVFAvN2w0QklsWU9oLytyVmYrY0ZrYlBXUXpLRmJXbjA9;Mgo+DSMBMAY9C3t2VFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5Xd0RjX39YdXRXRWVY;MTY4MzY3OEAzMjMxMmUzMTJlMzMzNURjNTZReGdzYlFxY3U0cXN1S2dDS1Z5MTc5TzBWSFNCVzFvT2duRE83K1E9;MTY4MzY3OUAzMjMxMmUzMTJlMzMzNVhRRWowblNaSUd2ZENLTlVFZW1USkd1RmF1SFRLSG4ydEIxV2M5SXduVTA9;MTY4MzY4MEAzMjMxMmUzMTJlMzMzNWk5dnlSNnNZcGduQUt1azg1aGFBNUhQc3ZOMmpZMWJ3Tloydm8zTFpIWkk9"
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
