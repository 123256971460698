import "./Wave.scss";

export default function WaveUp(props) {

  const pathClasses = "wave " + props.className;

  return (
    <div
      className="wave-up"
    >
      <svg
        viewBox="0 0 53 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className={pathClasses}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.60507 8.4946C8.92474 3.29323 16.2846 0 26.4155 0C36.5465 0 43.9063 3.29323 51.226 8.4946C52.942 9.71396 53.3456 12.0951 52.1276 13.8129C50.9096 15.5308 48.5311 15.9349 46.8152 14.7155C40.3939 10.1526 34.5465 7.62877 26.4155 7.62877C18.2846 7.62877 12.4371 10.1526 6.0159 14.7155C4.29994 15.9349 1.92148 15.5308 0.70346 13.8129C-0.514557 12.0951 -0.110895 9.71396 1.60507 8.4946Z"
          fill="#E0E0E0"
        />
      </svg>
    </div>
  );
}
