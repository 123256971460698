import "./LegendPart.scss";

export function LegendPart(props) {
  return (
    <div className="legend-part">
      <div className="line" style={{backgroundColor: `${props.color}`}}></div>
      <div className="progress-text">{props.rangeText}</div>
      <div className="progress-score">{props.from} - {props.to}</div>
    </div>
  );
}
