export function groupBy(iterable, keyFn, mapFn) {
  const groups = new Map();
  for (const item of iterable) {
    const key = keyFn(item);
    if (!groups.has(key)) groups.set(key, []);
    groups.get(key).push(mapFn ? mapFn(item) : item);
  }
  return groups;
}

export function calculateTimelineRange(propName, historyArr, forecastArr) {
  if (!historyArr?.length && !forecastArr?.length) {
    return "";
  }

  let chartDates;
  if (forecastArr?.length > 0) {
    chartDates = [...historyArr]
      ?.concat([...forecastArr])
      .map((point) => new Date(point[`${propName}`]));
  } else {
    chartDates = [...historyArr].map((point) => new Date(point[`${propName}`]));
  }

  let maxValue = new Date(Math.max(...chartDates))
    .toLocaleString("en-US", {
      month: "short",
      year: "2-digit",
      timeZone: "UTC",
    })
    .toLowerCase();
  let minValue = new Date(Math.min(...chartDates))
    .toLocaleString("en-US", {
      month: "short",
      year: "2-digit",
      timeZone: "UTC",
    })
    .toLowerCase();

  return `${minValue} ~ ${maxValue}`;
}

export function getChartRange(
  multiplier,
  propName,
  rangeDifference,
  historyArr,
  forecastArr = null
) {
  let chartPoints;

  if (!historyArr?.length && !forecastArr?.length) {
    return { max: 0, min: 0 };
  }

  if (forecastArr?.length > 0) {
    chartPoints = [...historyArr]
      ?.concat([...forecastArr])
      .map((point) => point[`${propName}`] * multiplier);
  } else {
    chartPoints = [...historyArr].map(
      (point) => point[`${propName}`] * multiplier
    );
  }

  let maxValue = Math.max(...chartPoints);
  let minValue = Math.min(...chartPoints);

  return {
    max: Math.round(maxValue) + rangeDifference,
    min: Math.round(minValue) - rangeDifference,
  };
}

export function groupByUniqueValues(array, keyFn) {
  const groups = new Map();
  for (const item of array) {
    const key = keyFn(item);

    if (!groups.has(key) && new Date(item.xValue) <= new Date()) {
      let countKeyValues = array.filter(
        (value) => keyFn(value) === key && new Date(value.xValue) <= new Date()
      );
      let avgKeyValue =
        countKeyValues?.length > 1
          ? countKeyValues?.reduce((a, b) => {
              return a + b?.yValue;
            }, 0) / countKeyValues?.length
          : 0;
      groups.set(
        key,
        countKeyValues?.length > 1
          ? { ...item, yValue: Math.round(avgKeyValue) }
          : item
      );
    }
  }

  return Array.from(groups, ([key, value]) => ({
    key,
    value,
  }));
}

export function roundNumber(number) {
  let result = Math.round(number);

  if (isNaN(result)) {
    return 0;
  }

  return result;
}

export function convertMetricToImperialHeight(metricHeight) {
  const imperialHeightInches = metricHeight * 0.393701;
  const feet = Math.floor(imperialHeightInches / 12);
  const inches = Math.round(imperialHeightInches % 12);
  return `${feet}' ${inches}"`;
}
