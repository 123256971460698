// Need to use the React-specific entry point to import createApi
import {
  calculateTimelineRange,
  getChartRange,
  groupBy,
  roundNumber,
  convertMetricToImperialHeight,
} from "../helpers/helper-functions";
import { apiService } from "./api";

export const extendedApiService = apiService.injectEndpoints({
  endpoints: (builder) => ({
    getReportDataById: builder.query({
      query: (id) => `reports/${id}`,
      transformResponse: (response, meta, args) => {
        let scoreSeries = [];

        let heartRateSeries = [];

        let stepsSeries = [];

        let sleepSeries = [];

        let weightSeries = [];

        let charts = [];
        let recommendations = [
          {
            id: "steps",
            title: "Steps Recommendations",
            description: response?.subscores?.stepsScoreLegend ?? null,
          },
          {
            id: "sleep",
            title: "Sleep Recommendations",
            description: response?.subscores?.sleepScoreLegend ?? null,
          },
          {
            id: "weight",
            title: "Weight Recommendations",
            description: response?.subscores?.weightScoreLegend ?? null,
          },
          {
            id: "bmi",
            title: "BMI Recommendations",
            description: response?.subscores?.bmiScoreLegend ?? null,
          },
        ];

        [response?.history?.score, response?.forecast?.score].map(
          (point, index) => {
            let isActual = index === 0;
            point = point.sort((a, b) => new Date(a.date) - new Date(b.date));
            point
              ?.filter((score) => score.value !== null)
              .map((element) => {
                scoreSeries.push({
                  xValue: `${new Date(element.date).toLocaleString("en-US", {
                    month: "2-digit",
                    day: "2-digit",
                    timeZone: "UTC",
                  })}`,
                  yValue: roundNumber(element.value),
                  color: isActual ? "rgb(15, 215, 143)" : "black",
                  isActual: isActual,
                });
              });
          }
        );

        [response?.history?.heartRate, response?.forecast?.heartRate].map(
          (point, index) => {
            let isActual = index === 0;
            point = point.sort((a, b) => new Date(a.date) - new Date(b.date));
            point
              ?.filter((score) => score.value !== null)
              .map((element) => {
                heartRateSeries.push({
                  xValue: `${new Date(element.date).toLocaleString("en-US", {
                    month: "2-digit",
                    day: "2-digit",
                    timeZone: "UTC",
                  })}`,
                  yValue: roundNumber(element.value),
                  color: isActual ? "rgb(15, 215, 143)" : "black",
                  isActual: isActual,
                });
              });
          }
        );

        [response?.history?.steps, response?.forecast?.steps].map(
          (point, index) => {
            let isActual = index === 0;
            point = point.sort((a, b) => new Date(a.date) - new Date(b.date));
            point
              ?.filter((score) => score.value !== null)
              .map((element) => {
                stepsSeries.push({
                  xValue: `${new Date(element.date).toLocaleString("en-US", {
                    month: "2-digit",
                    day: "2-digit",
                    timeZone: "UTC",
                  })}`,
                  yValue: roundNumber(element.value),
                  color: isActual ? "rgb(15, 215, 143)" : "black",
                  isActual: isActual,
                });
              });
          }
        );

        [response?.history?.sleep, response?.forecast?.sleep].map(
          (point, index) => {
            let isActual = index === 0;
            point = point.sort((a, b) => new Date(a.date) - new Date(b.date));
            point
              ?.filter((score) => score.value !== null)
              .map((element) => {
                sleepSeries.push({
                  xValue: `${new Date(element.date).toLocaleString("en-US", {
                    month: "2-digit",
                    day: "2-digit",
                    timeZone: "UTC",
                  })}`,
                  yValue: parseFloat(element.value.toFixed(2)),
                  color: isActual ? "rgb(15, 215, 143)" : "black",
                  isActual: isActual,
                });
              });
          }
        );

        [response?.history?.weight, response?.forecast?.weight].map(
          (point, index) => {
            let isActual = index === 0;
            point = point.sort((a, b) => new Date(a.date) - new Date(b.date));
            point
              ?.filter((score) => score.value !== null)
              .map((element) => {
                weightSeries.push({
                  xValue: `${new Date(element.date).toLocaleString("en-US", {
                    month: "2-digit",
                    day: "2-digit",
                    timeZone: "UTC",
                  })}`,
                  yValue: roundNumber(element.value * 2.20462),
                  color: isActual ? "rgb(15, 215, 143)" : "black",
                  isActual: isActual,
                });
              });
          }
        );

        let scoreChart = {
          id: "score",
          forecastLabel: "tallywell score values",
          recentTimelineValue: calculateTimelineRange(
            "date",
            response?.history?.score,
            response?.forecast?.score
          ),
          chartRange: getChartRange(
            1,
            "value",
            100,
            response?.history?.score,
            response?.forecast?.score
          ),
          forecastValue: response?.forecast?.score?.length ?? 0,
          series: scoreSeries,
          showAlways: true,
        };

        let heartRateChart = {
          id: "heart-rate",
          label: "Heart rate",
          progressValue:
            response?.subscores?.heartRateScoreLabel?.toLowerCase(),
          averageValue:
            heartRateSeries.findLast((score) => score.isActual)?.yValue ?? 0,
          value: "bpm",
          forecastLabel: "heart rate values",
          avgLabel: "Average",
          recentTimelineValue: calculateTimelineRange(
            "date",
            response?.history?.heartRate,
            response?.forecast?.heartRate
          ),
          chartRange: getChartRange(
            1,
            "value",
            100,
            response?.history?.heartRate,
            response?.forecast?.heartRate
          ),
          forecastValue: response?.forecast?.heartRate?.length ?? 0,
          series: heartRateSeries,
        };

        let stepsChart = {
          id: "steps",
          label: "Steps",
          progressValue: response?.subscores?.stepsScoreLabel?.toLowerCase(),
          averageValue:
            stepsSeries.findLast((score) => score.isActual)?.yValue ?? 0,
          value: "daily",
          forecastLabel: "steps values",
          avgLabel: "Average Steps",
          recentTimelineValue: calculateTimelineRange(
            "date",
            response?.history?.steps,
            response?.forecast?.steps
          ),
          chartRange: getChartRange(
            1,
            "value",
            100,
            response?.history?.steps,
            response?.forecast?.steps
          ),
          forecastValue: response?.forecast?.steps?.length ?? 0,
          series: stepsSeries,
        };

        let sleepChart = {
          id: "sleep",
          label: "Sleep",
          progressValue: response?.subscores?.sleepScoreLabel?.toLowerCase(),
          averageValue:
            sleepSeries.findLast((score) => score.isActual)?.yValue ?? 0,
          value: "hours daily",
          avgLabel: "Average Sleep",
          forecastLabel: "sleep values",
          recentTimelineValue: calculateTimelineRange(
            "date",
            response?.history?.sleep,
            response?.forecast?.sleep
          ),
          chartRange: getChartRange(
            1,
            "value",
            2,
            response?.history?.sleep,
            response?.forecast?.sleep
          ),
          forecastValue: response?.forecast?.sleep?.length ?? 0,
          series: sleepSeries,
        };

        let weightChart = {
          id: "weight",
          label: "Weight",
          progressValue: response?.subscores?.weightScoreLabel?.toLowerCase(),
          averageValue: roundNumber(
            response?.history?.weight[response?.history?.weight?.length - 1]
              ?.value * 2.20462
          ),
          value: "lbs",
          forecastLabel: "weight values",
          avgLabel: "Current Weight",
          recentTimelineValue: calculateTimelineRange(
            "date",
            response?.history?.weight,
            response?.forecast?.weight
          ),
          chartRange: getChartRange(
            2.20462,
            "value",
            100,
            response?.history?.weight,
            response?.forecast?.weight
          ),
          forecastValue: response?.forecast?.weight?.length ?? 0,
          series: weightSeries,
        };

        charts.push(
          scoreChart,
          heartRateChart,
          stepsChart,
          sleepChart,
          weightChart
        );

        let reportViewModel = {
          tokenId: response.tokenId,
          reportingDate: response.reportingDate,
          reportingDateWeek: response?.reportingDateWeek,
          userProfileGender: response?.profile?.gender.toLowerCase(),
          userProfileAge: response?.profile?.age,
          userProfileWeight: roundNumber(response?.profile?.weight * 2.20462),
          userProfileHeight: convertMetricToImperialHeight(
            response?.profile?.height
          ),
          userProfileCreatedOn: response?.profile?.createdOn,
          userIdentifier: response?.profile?.userIdentifier,
          scoreValue: roundNumber(response.scoreValue),
          scoreLabel: response.scoreLabel?.toLowerCase(),
          heartRateScoreValue: roundNumber(response?.subscores?.heartRateValue),
          heartRateScoreLabel:
            response?.subscores?.heartRateScoreLabel?.toLowerCase() ??
            "No data",
          stepsScoreValue: roundNumber(response?.subscores?.stepsValue),
          stepsScoreLabel:
            response?.subscores?.stepsScoreLabel?.toLowerCase() ?? "No data",
          sleepScoreValue: roundNumber(response?.subscores?.sleepValue),
          sleepScoreLabel:
            response?.subscores?.sleepScoreLabel?.toLowerCase() ?? "No data",
          bmiScoreValue: roundNumber(response?.subscores?.bmiValue),
          bmiScoreLabel:
            response?.subscores?.bmiScoreLabel?.toLowerCase() ?? "No data",
          weightScoreValue: roundNumber(
            response?.subscores?.weightValue * 2.20462
          ),
        };

        return {
          reportData: reportViewModel,
          charts: charts,
          recommendations: recommendations,
        };
      },
      transformErrorResponse: (response, meta, args) => {
        return response;
      },
    }),
    getReportsByUserId: builder.query({
      query: (userIdentifier) => `reports/${userIdentifier}/history`,
      transformResponse: (response, meta, args) => {

        response.sort((a, b) => new Date(a.createdOn) - new Date(b.createdOn));

        let reportHistory = groupBy(
          response,
          (item) => {
            return new Date(item.createdOn).toLocaleString("en-US", {
              year: "numeric",
              month: "long",
              timeZone: "UTC",
            });
          },
          (historyData) => {
            return {
              id: historyData.id,
              tokenId: historyData.token,
              userIdentifier: historyData.userIdentifier,
              period: new Date(historyData.createdOn).toLocaleString("en-US", {
                month: "long",
                year: "numeric",
                timeZone: "UTC",
              }),
              week: historyData.legend,
              scores: roundNumber(historyData.score),
              createdOn: new Date(historyData.createdOn).toLocaleString(
                "en-US",
                {
                  day: "2-digit",
                  month: "2-digit",
                  timeZone: "UTC",
                }
              ),
            };
          }
        );

        return {
          historyData: Array.from(reportHistory, ([key, value]) => ({
            key,
            value,
          })),
        };
      },
      transformErrorResponse: (response, meta, args) => {
        return response;
      },
    }),
  }),
});

export const { useGetReportDataByIdQuery, useGetReportsByUserIdQuery } =
  extendedApiService;
