import React from "react";
import { LegendPart } from "../LegendPart/LegendPart";
import styles from "./Legend.module.scss";

function Legend() {
  return (
    <div className={styles["progress-legend"]}>
      <LegendPart from={300} to={580} rangeText={"Poor"} color={"#FA679E"} />
      <LegendPart
        from={580}
        to={670}
        rangeText={"Needs work"}
        color={"#9747FF"}
      />
      <LegendPart from={670} to={740} rangeText={"Fair"} color={"#FFC33E"} />
      <LegendPart from={740} to={800} rangeText={"Good"} color={"#05EAEE"} />
      <LegendPart
        from={800}
        to={850}
        rangeText={"Excellent"}
        color={"#0FD78F"}
      />
    </div>
  );
}

export default React.memo(Legend);
