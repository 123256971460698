import { useState } from "react";
import HideChartIcon from "../ToggleChartIcons/HideChartIcon";
import ShowChartIcon from "../ToggleChartIcons/ShowChartIcon";
import "./ToggleChartBtn.scss";

export default function ToggleChartBtn(props) {
  const [buttonName, setButtonName] = useState("Show Chart");
  const [activeIcon, setActiveIcon] = useState(ShowChartIcon);

  function toggleElementHandler() {
    if (props.chartVisible) {
      setButtonName("Hide Chart");
      setActiveIcon(HideChartIcon);
    } else {
      setButtonName("Show Chart");
      setActiveIcon(ShowChartIcon);
    }
    props.onClick();
  }

  return (
    <button className="toggle-button" onClick={toggleElementHandler}>
      <span className="toggle-button-name">{buttonName}</span>
      {activeIcon}
    </button>
  );
}
