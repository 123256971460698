import "./SidenavSeparator.scss";

export default function SidenavSeparator() {
  return (
    <div className="sidenav-separator">
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 212 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.2">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.636026 3.39784C3.53653 1.31729 6.45296 0 10.4675 0C14.482 0 17.3984 1.31729 20.2989 3.39784C20.9789 3.88558 21.1388 4.83802 20.6562 5.52516C20.1735 6.21231 19.231 6.37395 18.5511 5.8862C16.0066 4.06103 13.6895 3.05151 10.4675 3.05151C7.24547 3.05151 4.92836 4.06103 2.38387 5.8862C1.7039 6.37395 0.761408 6.21231 0.278754 5.52516C-0.203899 4.83802 -0.0439433 3.88558 0.636026 3.39784ZM48.4045 3.39784C51.305 1.31729 54.2214 0 58.2359 0C62.2504 0 65.1669 1.31729 68.0674 3.39784C68.7473 3.88558 68.9073 4.83802 68.4246 5.52516C67.942 6.21231 66.9995 6.37395 66.3195 5.8862C63.775 4.06103 61.4579 3.05151 58.2359 3.05151C55.0139 3.05151 52.6968 4.06103 50.1523 5.8862C49.4724 6.37395 48.5299 6.21231 48.0472 5.52516C47.5646 4.83802 47.7245 3.88558 48.4045 3.39784ZM96.1701 3.39784C99.0706 1.31729 101.987 0 106.002 0C110.016 0 112.932 1.31729 115.833 3.39784C116.513 3.88558 116.673 4.83802 116.19 5.52516C115.708 6.21231 114.765 6.37395 114.085 5.8862C111.541 4.06103 109.224 3.05151 106.002 3.05151C102.78 3.05151 100.462 4.06103 97.918 5.8862C97.238 6.37395 96.2955 6.21231 95.8129 5.52516C95.3302 4.83802 95.4902 3.88558 96.1701 3.39784ZM24.1615 8.47484C24.6441 7.78769 25.5866 7.62605 26.2666 8.1138C28.8111 9.93897 31.1282 10.9485 34.3502 10.9485C37.5722 10.9485 39.8893 9.93897 42.4338 8.1138C43.1138 7.62605 44.0563 7.78769 44.5389 8.47484C45.0216 9.16198 44.8616 10.1144 44.1816 10.6022C41.2811 12.6827 38.3647 14 34.3502 14C30.3357 14 27.4193 12.6827 24.5188 10.6022C23.8388 10.1144 23.6788 9.16198 24.1615 8.47484ZM71.9301 8.47484C72.4128 7.78769 73.3553 7.62605 74.0352 8.1138C76.5797 9.93897 78.8968 10.9485 82.1188 10.9485C85.3408 10.9485 87.6579 9.93897 90.2024 8.1138C90.8824 7.62605 91.8249 7.78769 92.3075 8.47484C92.7902 9.16198 92.6302 10.1144 91.9502 10.6022C89.0497 12.6827 86.1333 14 82.1188 14C78.1043 14 75.1879 12.6827 72.2874 10.6022C71.6074 10.1144 71.4475 9.16198 71.9301 8.47484Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M143.935 3.39784C146.836 1.31729 149.752 0 153.767 0C157.781 0 160.698 1.31729 163.598 3.39784C164.278 3.88558 164.438 4.83802 163.956 5.52516C163.473 6.21231 162.53 6.37395 161.851 5.8862C159.306 4.06103 156.989 3.05151 153.767 3.05151C150.545 3.05151 148.228 4.06103 145.683 5.8862C145.003 6.37395 144.061 6.21231 143.578 5.52516C143.096 4.83802 143.256 3.88558 143.935 3.39784ZM191.701 3.39784C194.602 1.31729 197.518 0 201.533 0C205.547 0 208.463 1.31729 211.364 3.39784C212.044 3.88558 212.204 4.83802 211.721 5.52516C211.239 6.21231 210.296 6.37395 209.616 5.8862C207.072 4.06103 204.755 3.05151 201.533 3.05151C198.311 3.05151 195.993 4.06103 193.449 5.8862C192.769 6.37395 191.826 6.21231 191.344 5.52516C190.861 4.83802 191.021 3.88558 191.701 3.39784ZM119.692 8.47484C120.175 7.78769 121.118 7.62605 121.798 8.1138C124.342 9.93897 126.659 10.9485 129.881 10.9485C133.103 10.9485 135.42 9.93897 137.965 8.1138C138.645 7.62605 139.587 7.78769 140.07 8.47484C140.553 9.16198 140.393 10.1144 139.713 10.6022C136.812 12.6827 133.896 14 129.881 14C125.867 14 122.95 12.6827 120.05 10.6022C119.37 10.1144 119.21 9.16198 119.692 8.47484ZM167.461 8.47484C167.944 7.78769 168.886 7.62605 169.566 8.1138C172.111 9.93897 174.428 10.9485 177.65 10.9485C180.872 10.9485 183.189 9.93897 185.733 8.1138C186.413 7.62605 187.356 7.78769 187.839 8.47484C188.321 9.16198 188.161 10.1144 187.481 10.6022C184.581 12.6827 181.664 14 177.65 14C173.635 14 170.719 12.6827 167.818 10.6022C167.138 10.1144 166.978 9.16198 167.461 8.47484Z"
            fill="white"
          />
        </g>
      </svg>
    </div>
  );
}
