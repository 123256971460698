import "./ShowMenuIcon.scss";

export function ShowMenuIcon() {
  return (
    <div className="logo">
      <svg
        width="26"
        height="20"
        viewBox="0 0 26 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 18.5C0 17.6716 0.623603 17 1.39286 17H24.6071C25.3764 17 26 17.6716 26 18.5C26 19.3284 25.3764 20 24.6071 20H1.39286C0.623603 20 0 19.3284 0 18.5Z"
          fill="white"
        />
        <path
          d="M0 2C0 1.17157 0.623603 0.5 1.39286 0.5H24.6071C25.3764 0.5 26 1.17157 26 2C26 2.82843 25.3764 3.5 24.6071 3.5H1.39286C0.623603 3.5 0 2.82843 0 2Z"
          fill="white"
        />
        <rect y="9" width="16" height="3" rx="1.5" fill="#898989" />
      </svg>
    </div>
  );
}
