import { configureStore } from "@reduxjs/toolkit";
import { apiService } from "../services/api";
import dashboardSlice from "./dashboard-slice";
import sidenavSlice from "./sidenav-slice";

export const store = configureStore({
  reducer: {
    dashboard: dashboardSlice,
    sidenav: sidenavSlice,
    [apiService.reducerPath]: apiService.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      apiService.middleware
    ),
    devTools: process.env.REACT_APP_DEVTOOLS ? true : false
});
