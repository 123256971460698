import styles from "./MinorIndicators.module.scss";

export default function MinorIndicators(props) {
  return (
    <div
      id={`${props.chart.id}-container-indicators`}
      className={`${styles.indicators} ${
        props.state ? styles["display-flex"] : styles["display-none"]
      }`}
    >
      <div className={styles["left-side-data"]}>
        <span className={styles.label}>Recent Timeline</span>
        <span className={styles.range}>{props.chart.recentTimelineValue}</span>
      </div>
      <div className={styles["right-side-data"]}>
        <span className={styles.label}>Forecast</span>
        <span className={styles.range}>
          {`${props.chart?.forecastValue} ${props.chart?.forecastLabel}`}
        </span>
      </div>
    </div>
  );
}
