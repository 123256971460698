import Tooltip from "../Tooltip/Tooltip";
import WaveLine from "../Waves/WaveLine";
import styles from "./WavesGroup.module.scss";

export default function WavesGroup(props) {

  return (
    <div className={`${styles["waves-container"]} ${styles.desktop}`}>
      <Tooltip content={`Heart rate: ${props.heartRateScoreLabel}`}>
        <WaveLine
          waveScore={props.heartRateScoreValue}
          waveProgress={props.heartRateScoreLabel}
          mirrorEffect={props.mirrorEffect}
        />
      </Tooltip>
      <Tooltip content={`Steps: ${props.stepsScoreLabel}`}>
        <WaveLine
          waveScore={props.stepsScoreValue}
          waveProgress={props.stepsScoreLabel}
          mirrorEffect={props.mirrorEffect}
        />
      </Tooltip>
      <Tooltip content={`Sleep: ${props.sleepScoreLabel}`}>
        <WaveLine
          waveScore={props.sleepScoreValue}
          waveProgress={props.sleepScoreLabel}
          mirrorEffect={props.mirrorEffect}
        />
      </Tooltip>
      <Tooltip content={`BMI: ${props.bmiScoreLabel}`}>
        <WaveLine
          waveScore={props.bmiScoreValue}
          waveProgress={props.bmiScoreLabel}
          mirrorEffect={props.mirrorEffect}
        />
      </Tooltip>
    </div>
  );
}
