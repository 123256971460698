import "./ToggleChartIcons.scss";

export default function ShowChartIcon() {
  return (
    <div className="chart-icon">
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect className="show-icon" width="4" height="24" fill="white" />
        <rect
          className="show-icon"
          x="8"
          y="12"
          width="4"
          height="12"
          fill="#535353"
        />
        <rect
          className="show-icon"
          x="16"
          y="5"
          width="4"
          height="19"
          fill="white"
        />
      </svg>
    </div>
  );
}
