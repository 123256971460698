/* App.js */
import React from "react";
import Dashboard from "./app/pages/dashboard";
import "./App.scss";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { NotFound } from "./app/pages/not-found";
import MainLayout from "./app/components/layouts/MainLayout";

const router = createBrowserRouter([
  {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        path: "/:id",
        element: <Dashboard />,
      },
    ],
  },
  { path: "*", element: <NotFound /> },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
