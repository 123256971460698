import {
  ChartComponent,
  SeriesCollectionDirective,
  SeriesDirective,
  TrendlineDirective,
  TrendlinesDirective,
  Inject,
  Tooltip,
  LineSeries,
  ScatterSeries,
  SplineSeries,
  Trendlines,
  Category,
  Zoom,
  DateTime,
  DataLabel,
  ScrollBar,
} from "@syncfusion/ej2-react-charts";
import { useRef, useEffect } from "react";

function TrendlineChart({ id, min, max, series, state }) {
  const chartRef = useRef();

  const primaryxAxis = {
    title: "",
    majorGridLines: { width: 0 },
    majorTickLines: { width: 0 },
    valueType: "Category",
    titleStyle: {
      fontFamily: "ArticulatCFNormal",
    },
    labelStyle: {
      fontFamily: "ArticulatCFNormal",
    },
  };
  const primaryyAxis = {
    title: "",
    majorGridLines: { width: 0 },
    majorTickLines: { width: 0 },
    maximum: max,
    minimum: min,
    edgeLabelPlacement: "Shift",
    enableAutoIntervalOnZooming: true,
    labelIntersectAction: "Rotate45",
    titleStyle: {
      fontFamily: "ArticulatCFNormal",
    },
    labelStyle: {
      fontFamily: "ArticulatCFNormal",
    },
  };
  const tooltip = { enable: true, textStyle: { fontFamily: "ArticulatCFNormal" } };
  const chartarea = { border: { width: 1 } };
  const marker = {
    visible: true,
    height: 15,
    width: 15,
    dataLabel: {
      visible: true,
      name: "text",
      font: {
        fontFamily: "ArticulatCFNormal",
      },
    },
  };

  useEffect(() => {
    if (id !== "score") {
      chartRef.current.refresh();
    }
  }, [id, state]);

  return (
    <ChartComponent
      id={id}
      ref={chartRef}
      class="trendline-chart"
      primaryXAxis={primaryxAxis}
      primaryYAxis={primaryyAxis}
      tooltip={tooltip}
      chartArea={chartarea}
      background={"black"}
      zoomSettings={{
        //enableMouseWheelZooming: true,
        enablePinchZooming: false,
        enableSelectionZooming: false,
        enablePan: false,
        enableScrollbar: true,
      }}
      width={"100%"}
      height={"100%"}
      title=""
    >
      <Inject
        services={[
          Category,
          Tooltip,
          ScatterSeries,
          SplineSeries,
          LineSeries,
          Trendlines,
          Zoom,
          DateTime,
          DataLabel,
          ScrollBar,
        ]}
      />
      <SeriesCollectionDirective>
        <SeriesDirective
          dataSource={series}
          xName="xValue"
          yName="yValue"
          type="Scatter"
          marker={marker}
          pointColorMapping="color"
          border={{ color: "rgb(15, 215, 143)", width: 1 }}
        >
          <TrendlinesDirective>
            <TrendlineDirective
              type="Linear"
              width={2}
              fill="white"
              marker={{
                dataLabel: {
                  font: {
                    fontFamily: "ArticulatCFNormal",
                  },
                },
              }}
            ></TrendlineDirective>
          </TrendlinesDirective>
        </SeriesDirective>
      </SeriesCollectionDirective>
    </ChartComponent>
  );
}
export default TrendlineChart;
