import React, { useState } from "react";
import MajorIndicators from "../MajorIndicators/MajorIndicators";
import MinorIndicators from "../MinorIndicators/MinorIndicators";
import ChartSeparator from "../Separator/ChartSeparator";
import ChartLegend from "../ChartLegend/ChartLegend";
import TrendlineChart from "../TrendlineChart/TrendlineChart";
import styles from "./TrendlineChartContainer.module.scss";

function TrendlineChartContainer({ chart }) {
  const [isOpened, setIsOpened] = useState(false);
  const minScoresRequired = 2;

  let shouldShowChart =
    chart?.series?.length >= minScoresRequired;

  return (
    <>
      {shouldShowChart && (
        <React.Fragment key={chart.id}>
          {!chart.showAlways && (
            <MajorIndicators
              onClick={() => setIsOpened((isOpened) => !isOpened)}
              chart={chart}
              state={isOpened}
              hasProgressValue={true}
            />
          )}
          <MinorIndicators chart={chart} state={chart.showAlways ?? isOpened} />
          <div
            className={`${styles["chart-lenend-container"]} ${
              isOpened || chart.showAlways
                ? styles["display-flex"]
                : styles["display-none"]
            }`}
          >
            <ChartLegend />
          </div>
          <div
            id={`${chart.id}-container`}
            className={`${styles.chart} ${
              chart.showAlways || isOpened ? "" : styles["display-none"]
            }`}
          >
            <TrendlineChart
              max={chart.chartRange.max}
              min={chart.chartRange.min}
              id={`${chart.id}`}
              series={chart.series}
              state={chart.showAlways ?? isOpened}
            />
          </div>
          <div className={styles["separator-container"]}>
            <ChartSeparator />
          </div>
        </React.Fragment>
      )}
      {!shouldShowChart && (
        <>
          <div className={styles["no-data"]}>
            We don't have enough HealthKit data to display your {chart.id.replace("-", " ") + ' '}
            chart
          </div>
          <div className={styles["separator-container"]}>
            <ChartSeparator />
          </div>
        </>
      )}
    </>
  );
}

export default TrendlineChartContainer;
