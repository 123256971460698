import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { Header } from "./Header/Header";
import { Sidenav } from "./Sidenav/Sidenav";

export default function MainLayout() {
  const isSidenavOpened = useSelector((state) => state.sidenav.isOpened);
  return (
    <div className="drawer-container">
      <Header />
      <Sidenav isOpen={isSidenavOpened} />
      <div className="main-container">
        <div className="drawer-content">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
