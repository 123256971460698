import React from "react";
import styles from "./ChartLegend.module.scss";

function ChartLegend() {
  return (
    <div className={styles["chart-legend"]}>
      <div className={styles.historical}>
        <span className={`${styles["circle-shape"]} ${styles.green}`}></span>
        <span className={styles.name}>Actual scores</span>
      </div>
      <div className={styles.future}>
        <span className={`${styles["circle-shape"]} ${styles.black}`}></span>
        <span className={styles.name}>Forecasted scores</span>
      </div>
      <div className={styles.line}>
        <div className={`${styles["line-shape"]} ${styles.black}`}></div>
        <span className={styles.name}>Trend line</span>
      </div>
    </div>
  );
}

export default React.memo(ChartLegend);
