import React from "react";
import "./ChartSeparator.scss";

function ChartSeparator() {
  return (
    <div className="chart-separator">
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 1679 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.998262 5.09676C5.55069 1.97594 10.1281 0 16.429 0C22.7299 0 27.3073 1.97594 31.8597 5.09676C32.927 5.82838 33.178 7.25703 32.4205 8.28774C31.6629 9.31846 30.1837 9.56092 29.1164 8.8293C25.1228 6.09154 21.486 4.57726 16.429 4.57726C11.372 4.57726 7.7352 6.09154 3.74155 8.8293C2.67432 9.56092 1.19505 9.31846 0.437513 8.28774C-0.320026 7.25703 -0.0689704 5.82838 0.998262 5.09676Z"
          fill="#535353"
        />
        <path
          d="M37.9222 12.7123C38.6797 11.6815 40.159 11.4391 41.2262 12.1707C45.2199 14.9085 48.8567 16.4227 53.9137 16.4227C58.9707 16.4227 62.6075 14.9085 66.6011 12.1707C67.6684 11.4391 69.1476 11.6815 69.9052 12.7123C70.6627 13.743 70.4117 15.1716 69.3444 15.9032C64.792 19.0241 60.2146 21 53.9137 21C47.6128 21 43.0354 19.0241 38.4829 15.9032C37.4157 15.1716 37.1647 13.743 37.9222 12.7123Z"
          fill="#535353"
        />
        <path
          d="M75.9723 5.09676C80.5248 1.97594 85.1022 0 91.4031 0C97.7039 0 102.281 1.97594 106.834 5.09676C107.901 5.82838 108.152 7.25703 107.395 8.28774C106.637 9.31846 105.158 9.56092 104.091 8.8293C100.097 6.09154 96.4601 4.57726 91.4031 4.57726C86.3461 4.57726 82.7093 6.09154 78.7156 8.8293C77.6484 9.56092 76.1691 9.31846 75.4116 8.28774C74.654 7.25703 74.9051 5.82838 75.9723 5.09676Z"
          fill="#535353"
        />
        <path
          d="M112.896 12.7123C113.654 11.6815 115.133 11.4391 116.201 12.1707C120.194 14.9085 123.831 16.4227 128.888 16.4227C133.945 16.4227 137.582 14.9085 141.575 12.1707C142.643 11.4391 144.122 11.6815 144.879 12.7123C145.637 13.743 145.386 15.1716 144.319 15.9032C139.766 19.0241 135.189 21 128.888 21C122.587 21 118.01 19.0241 113.457 15.9032C112.39 15.1716 112.139 13.743 112.896 12.7123Z"
          fill="#535353"
        />
        <path
          d="M150.942 5.09676C155.494 1.97594 160.072 0 166.373 0C172.673 0 177.251 1.97594 181.803 5.09676C182.871 5.82838 183.122 7.25703 182.364 8.28774C181.607 9.31846 180.127 9.56092 179.06 8.8293C175.066 6.09154 171.43 4.57726 166.373 4.57726C161.316 4.57726 157.679 6.09155 153.685 8.8293C152.618 9.56092 151.139 9.31846 150.381 8.28774C149.624 7.25703 149.875 5.82838 150.942 5.09676Z"
          fill="#535353"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M187.861 12.7123C188.618 11.6815 190.098 11.4391 191.165 12.1707C195.159 14.9085 198.795 16.4227 203.852 16.4227C208.909 16.4227 212.546 14.9085 216.54 12.1707C217.607 11.4391 219.086 11.6815 219.844 12.7123C220.601 13.743 220.35 15.1716 219.283 15.9032C214.731 19.0241 210.153 21 203.852 21C197.552 21 192.974 19.0241 188.422 15.9032C187.354 15.1716 187.103 13.743 187.861 12.7123Z"
          fill="#535353"
        />
        <path
          d="M225.911 5.09676C230.463 1.97594 235.041 0 241.342 0C247.643 0 252.22 1.97594 256.772 5.09676C257.84 5.82838 258.091 7.25703 257.333 8.28774C256.576 9.31846 255.096 9.56092 254.029 8.8293C250.036 6.09154 246.399 4.57726 241.342 4.57726C236.285 4.57726 232.648 6.09154 228.654 8.8293C227.587 9.56092 226.108 9.31846 225.35 8.28774C224.593 7.25703 224.844 5.82838 225.911 5.09676Z"
          fill="#535353"
        />
        <path
          d="M262.835 12.7123C263.593 11.6815 265.072 11.4391 266.139 12.1707C270.133 14.9085 273.77 16.4227 278.827 16.4227C283.884 16.4227 287.52 14.9085 291.514 12.1707C292.581 11.4391 294.061 11.6815 294.818 12.7123C295.576 13.743 295.325 15.1716 294.257 15.9032C289.705 19.0241 285.128 21 278.827 21C272.526 21 267.948 19.0241 263.396 15.9032C262.329 15.1716 262.078 13.743 262.835 12.7123Z"
          fill="#535353"
        />
        <path
          d="M300.881 5.09676C305.433 1.97594 310.01 0 316.311 0C322.612 0 327.19 1.97594 331.742 5.09676C332.809 5.82838 333.06 7.25703 332.303 8.28774C331.545 9.31846 330.066 9.56092 328.999 8.8293C325.005 6.09154 321.368 4.57726 316.311 4.57726C311.254 4.57726 307.618 6.09155 303.624 8.8293C302.557 9.56092 301.077 9.31846 300.32 8.28774C299.562 7.25703 299.813 5.82838 300.881 5.09676Z"
          fill="#535353"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M337.563 15.9032C342.116 19.0241 346.693 21 352.994 21C359.295 21 363.872 19.0241 368.425 15.9032C369.492 15.1716 369.743 13.743 368.985 12.7123C368.228 11.6815 366.749 11.4391 365.681 12.1707C361.688 14.9085 358.051 16.4227 352.994 16.4227C347.937 16.4227 344.3 14.9085 340.306 12.1707C339.239 11.4391 337.76 11.6815 337.002 12.7123C336.245 13.743 336.496 15.1716 337.563 15.9032Z"
          fill="#535353"
        />
        <path
          d="M374.487 8.28774C375.245 9.31846 376.724 9.56092 377.791 8.8293C381.785 6.09154 385.422 4.57726 390.479 4.57726C395.536 4.57726 399.172 6.09155 403.166 8.8293C404.233 9.56092 405.713 9.31846 406.47 8.28774C407.228 7.25703 406.977 5.82837 405.909 5.09676C401.357 1.97594 396.779 0 390.479 0C384.178 0 379.6 1.97594 375.048 5.09676C373.981 5.82837 373.73 7.25703 374.487 8.28774Z"
          fill="#535353"
        />
        <path
          d="M412.537 15.9032C417.09 19.0241 421.667 21 427.968 21C434.269 21 438.846 19.0241 443.399 15.9032C444.466 15.1716 444.717 13.743 443.959 12.7123C443.202 11.6815 441.723 11.4391 440.655 12.1707C436.662 14.9085 433.025 16.4227 427.968 16.4227C422.911 16.4227 419.274 14.9085 415.281 12.1707C414.213 11.4391 412.734 11.6815 411.977 12.7123C411.219 13.743 411.47 15.1716 412.537 15.9032Z"
          fill="#535353"
        />
        <path
          d="M449.461 8.28774C450.219 9.31846 451.698 9.56092 452.765 8.8293C456.759 6.09155 460.396 4.57726 465.453 4.57726C470.51 4.57726 474.147 6.09154 478.14 8.8293C479.208 9.56092 480.687 9.31846 481.444 8.28774C482.202 7.25703 481.951 5.82837 480.884 5.09676C476.331 1.97594 471.754 0 465.453 0C459.152 0 454.575 1.97594 450.022 5.09676C448.955 5.82837 448.704 7.25703 449.461 8.28774Z"
          fill="#535353"
        />
        <path
          d="M487.507 15.9032C492.059 19.0241 496.637 21 502.938 21C509.238 21 513.816 19.0241 518.368 15.9032C519.435 15.1716 519.687 13.743 518.929 12.7123C518.171 11.6815 516.692 11.4391 515.625 12.1707C511.631 14.9085 507.995 16.4227 502.938 16.4227C497.88 16.4227 494.244 14.9085 490.25 12.1707C489.183 11.4391 487.704 11.6815 486.946 12.7123C486.189 13.743 486.44 15.1716 487.507 15.9032Z"
          fill="#535353"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M524.426 8.28774C525.183 9.31846 526.663 9.56092 527.73 8.8293C531.724 6.09154 535.36 4.57726 540.417 4.57726C545.474 4.57726 549.111 6.09155 553.105 8.8293C554.172 9.56092 555.651 9.31846 556.409 8.28774C557.166 7.25703 556.915 5.82838 555.848 5.09676C551.296 1.97594 546.718 0 540.417 0C534.116 0 529.539 1.97594 524.987 5.09676C523.919 5.82838 523.668 7.25703 524.426 8.28774Z"
          fill="#535353"
        />
        <path
          d="M562.476 15.9032C567.028 19.0241 571.606 21 577.907 21C584.208 21 588.785 19.0241 593.337 15.9032C594.405 15.1716 594.656 13.743 593.898 12.7123C593.141 11.6815 591.661 11.4391 590.594 12.1707C586.6 14.9085 582.964 16.4227 577.907 16.4227C572.85 16.4227 569.213 14.9085 565.219 12.1707C564.152 11.4391 562.673 11.6815 561.915 12.7123C561.158 13.743 561.409 15.1716 562.476 15.9032Z"
          fill="#535353"
        />
        <path
          d="M599.4 8.28774C600.158 9.31846 601.637 9.56092 602.704 8.8293C606.698 6.09155 610.335 4.57726 615.392 4.57726C620.449 4.57726 624.085 6.09154 628.079 8.8293C629.146 9.56092 630.626 9.31846 631.383 8.28774C632.141 7.25703 631.89 5.82837 630.822 5.09676C626.27 1.97594 621.693 0 615.392 0C609.091 0 604.513 1.97594 599.961 5.09676C598.894 5.82837 598.643 7.25703 599.4 8.28774Z"
          fill="#535353"
        />
        <path
          d="M637.446 15.9032C641.998 19.0241 646.575 21 652.876 21C659.177 21 663.755 19.0241 668.307 15.9032C669.374 15.1716 669.625 13.743 668.868 12.7123C668.11 11.6815 666.631 11.4391 665.564 12.1707C661.57 14.9085 657.933 16.4227 652.876 16.4227C647.819 16.4227 644.182 14.9085 640.189 12.1707C639.122 11.4391 637.642 11.6815 636.885 12.7123C636.127 13.743 636.378 15.1716 637.446 15.9032Z"
          fill="#535353"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M674.128 5.09676C678.681 1.97594 683.258 0 689.559 0C695.86 0 700.437 1.97594 704.99 5.09676C706.057 5.82838 706.308 7.25703 705.55 8.28774C704.793 9.31846 703.313 9.56092 702.246 8.8293C698.253 6.09154 694.616 4.57726 689.559 4.57726C684.502 4.57726 680.865 6.09154 676.871 8.8293C675.804 9.56092 674.325 9.31846 673.567 8.28774C672.81 7.25703 673.061 5.82838 674.128 5.09676Z"
          fill="#535353"
        />
        <path
          d="M711.052 12.7123C711.81 11.6815 713.289 11.4391 714.356 12.1707C718.35 14.9085 721.986 16.4227 727.044 16.4227C732.101 16.4227 735.737 14.9085 739.731 12.1707C740.798 11.4391 742.277 11.6815 743.035 12.7123C743.793 13.743 743.542 15.1716 742.474 15.9032C737.922 19.0241 733.344 21 727.044 21C720.743 21 716.165 19.0241 711.613 15.9032C710.546 15.1716 710.294 13.743 711.052 12.7123Z"
          fill="#535353"
        />
        <path
          d="M749.102 5.09676C753.655 1.97594 758.232 0 764.533 0C770.834 0 775.411 1.97594 779.964 5.09676C781.031 5.82838 781.282 7.25703 780.524 8.28774C779.767 9.31846 778.288 9.56092 777.22 8.8293C773.227 6.09154 769.59 4.57726 764.533 4.57726C759.476 4.57726 755.839 6.09154 751.845 8.8293C750.778 9.56092 749.299 9.31846 748.541 8.28774C747.784 7.25703 748.035 5.82838 749.102 5.09676Z"
          fill="#535353"
        />
        <path
          d="M786.026 12.7123C786.784 11.6815 788.263 11.4391 789.33 12.1707C793.324 14.9085 796.961 16.4227 802.018 16.4227C807.075 16.4227 810.712 14.9085 814.705 12.1707C815.772 11.4391 817.252 11.6815 818.009 12.7123C818.767 13.743 818.516 15.1716 817.449 15.9032C812.896 19.0241 808.319 21 802.018 21C795.717 21 791.14 19.0241 786.587 15.9032C785.52 15.1716 785.269 13.743 786.026 12.7123Z"
          fill="#535353"
        />
        <path
          d="M824.072 5.09676C828.624 1.97594 833.202 0 839.502 0C845.803 0 850.381 1.97594 854.933 5.09676C856 5.82838 856.251 7.25703 855.494 8.28774C854.736 9.31846 853.257 9.56092 852.19 8.8293C848.196 6.09154 844.559 4.57726 839.502 4.57726C834.445 4.57726 830.809 6.09155 826.815 8.8293C825.748 9.56092 824.268 9.31846 823.511 8.28774C822.753 7.25703 823.004 5.82838 824.072 5.09676Z"
          fill="#535353"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M860.991 12.7123C861.748 11.6815 863.228 11.4391 864.295 12.1707C868.288 14.9085 871.925 16.4227 876.982 16.4227C882.039 16.4227 885.676 14.9085 889.67 12.1707C890.737 11.4391 892.216 11.6815 892.974 12.7123C893.731 13.743 893.48 15.1716 892.413 15.9032C887.861 19.0241 883.283 21 876.982 21C870.681 21 866.104 19.0241 861.552 15.9032C860.484 15.1716 860.233 13.743 860.991 12.7123Z"
          fill="#535353"
        />
        <path
          d="M899.041 5.09676C903.593 1.97594 908.171 0 914.472 0C920.773 0 925.35 1.97594 929.902 5.09676C930.97 5.82838 931.221 7.25703 930.463 8.28774C929.706 9.31846 928.226 9.56092 927.159 8.8293C923.165 6.09154 919.529 4.57726 914.472 4.57726C909.415 4.57726 905.778 6.09154 901.784 8.8293C900.717 9.56092 899.238 9.31846 898.48 8.28774C897.723 7.25703 897.974 5.82838 899.041 5.09676Z"
          fill="#535353"
        />
        <path
          d="M935.965 12.7123C936.723 11.6815 938.202 11.4391 939.269 12.1707C943.263 14.9085 946.9 16.4227 951.957 16.4227C957.014 16.4227 960.65 14.9085 964.644 12.1707C965.711 11.4391 967.19 11.6815 967.948 12.7123C968.706 13.743 968.455 15.1716 967.387 15.9032C962.835 19.0241 958.257 21 951.957 21C945.656 21 941.078 19.0241 936.526 15.9032C935.459 15.1716 935.208 13.743 935.965 12.7123Z"
          fill="#535353"
        />
        <path
          d="M974.01 5.09676C978.563 1.97594 983.14 0 989.441 0C995.742 0 1000.32 1.97594 1004.87 5.09676C1005.94 5.82838 1006.19 7.25703 1005.43 8.28774C1004.68 9.31846 1003.2 9.56092 1002.13 8.8293C998.135 6.09154 994.498 4.57726 989.441 4.57726C984.384 4.57726 980.747 6.09155 976.754 8.8293C975.687 9.56092 974.207 9.31846 973.45 8.28774C972.692 7.25703 972.943 5.82838 974.01 5.09676Z"
          fill="#535353"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1010.69 15.9032C1015.25 19.0241 1019.82 21 1026.12 21C1032.42 21 1037 19.0241 1041.55 15.9032C1042.62 15.1716 1042.87 13.743 1042.12 12.7123C1041.36 11.6815 1039.88 11.4391 1038.81 12.1707C1034.82 14.9085 1031.18 16.4227 1026.12 16.4227C1021.07 16.4227 1017.43 14.9085 1013.44 12.1707C1012.37 11.4391 1010.89 11.6815 1010.13 12.7123C1009.37 13.743 1009.63 15.1716 1010.69 15.9032Z"
          fill="#535353"
        />
        <path
          d="M1047.62 8.28774C1048.37 9.31846 1049.85 9.56092 1050.92 8.8293C1054.91 6.09154 1058.55 4.57726 1063.61 4.57726C1068.67 4.57726 1072.3 6.09155 1076.3 8.8293C1077.36 9.56092 1078.84 9.31846 1079.6 8.28774C1080.36 7.25703 1080.11 5.82837 1079.04 5.09676C1074.49 1.97594 1069.91 0 1063.61 0C1057.31 0 1052.73 1.97594 1048.18 5.09676C1047.11 5.82837 1046.86 7.25703 1047.62 8.28774Z"
          fill="#535353"
        />
        <path
          d="M1085.67 15.9032C1090.22 19.0241 1094.8 21 1101.1 21C1107.4 21 1111.98 19.0241 1116.53 15.9032C1117.6 15.1716 1117.85 13.743 1117.09 12.7123C1116.33 11.6815 1114.85 11.4391 1113.79 12.1707C1109.79 14.9085 1106.15 16.4227 1101.1 16.4227C1096.04 16.4227 1092.4 14.9085 1088.41 12.1707C1087.34 11.4391 1085.86 11.6815 1085.11 12.7123C1084.35 13.743 1084.6 15.1716 1085.67 15.9032Z"
          fill="#535353"
        />
        <path
          d="M1122.59 8.28774C1123.35 9.31846 1124.83 9.56092 1125.9 8.8293C1129.89 6.09155 1133.53 4.57726 1138.58 4.57726C1143.64 4.57726 1147.28 6.09154 1151.27 8.8293C1152.34 9.56092 1153.82 9.31846 1154.57 8.28774C1155.33 7.25703 1155.08 5.82837 1154.01 5.09676C1149.46 1.97594 1144.88 0 1138.58 0C1132.28 0 1127.7 1.97594 1123.15 5.09676C1122.08 5.82837 1121.83 7.25703 1122.59 8.28774Z"
          fill="#535353"
        />
        <path
          d="M1160.64 15.9032C1165.19 19.0241 1169.77 21 1176.07 21C1182.37 21 1186.95 19.0241 1191.5 15.9032C1192.57 15.1716 1192.82 13.743 1192.06 12.7123C1191.3 11.6815 1189.82 11.4391 1188.75 12.1707C1184.76 14.9085 1181.12 16.4227 1176.07 16.4227C1171.01 16.4227 1167.37 14.9085 1163.38 12.1707C1162.31 11.4391 1160.83 11.6815 1160.08 12.7123C1159.32 13.743 1159.57 15.1716 1160.64 15.9032Z"
          fill="#535353"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1197.56 8.28774C1198.31 9.31846 1199.79 9.56092 1200.86 8.8293C1204.85 6.09154 1208.49 4.57726 1213.55 4.57726C1218.6 4.57726 1222.24 6.09155 1226.23 8.8293C1227.3 9.56092 1228.78 9.31846 1229.54 8.28774C1230.3 7.25703 1230.05 5.82838 1228.98 5.09676C1224.43 1.97594 1219.85 0 1213.55 0C1207.25 0 1202.67 1.97594 1198.12 5.09676C1197.05 5.82838 1196.8 7.25703 1197.56 8.28774Z"
          fill="#535353"
        />
        <path
          d="M1235.61 15.9032C1240.16 19.0241 1244.74 21 1251.04 21C1257.34 21 1261.91 19.0241 1266.47 15.9032C1267.53 15.1716 1267.79 13.743 1267.03 12.7123C1266.27 11.6815 1264.79 11.4391 1263.72 12.1707C1259.73 14.9085 1256.09 16.4227 1251.04 16.4227C1245.98 16.4227 1242.34 14.9085 1238.35 12.1707C1237.28 11.4391 1235.8 11.6815 1235.04 12.7123C1234.29 13.743 1234.54 15.1716 1235.61 15.9032Z"
          fill="#535353"
        />
        <path
          d="M1272.53 8.28774C1273.29 9.31846 1274.77 9.56092 1275.83 8.8293C1279.83 6.09155 1283.46 4.57726 1288.52 4.57726C1293.58 4.57726 1297.22 6.09154 1301.21 8.8293C1302.28 9.56092 1303.76 9.31846 1304.51 8.28774C1305.27 7.25703 1305.02 5.82837 1303.95 5.09676C1299.4 1.97594 1294.82 0 1288.52 0C1282.22 0 1277.64 1.97594 1273.09 5.09676C1272.02 5.82837 1271.77 7.25703 1272.53 8.28774Z"
          fill="#535353"
        />
        <path
          d="M1310.58 15.9032C1315.13 19.0241 1319.71 21 1326.01 21C1332.31 21 1336.88 19.0241 1341.44 15.9032C1342.5 15.1716 1342.76 13.743 1342 12.7123C1341.24 11.6815 1339.76 11.4391 1338.69 12.1707C1334.7 14.9085 1331.06 16.4227 1326.01 16.4227C1320.95 16.4227 1317.31 14.9085 1313.32 12.1707C1312.25 11.4391 1310.77 11.6815 1310.01 12.7123C1309.26 13.743 1309.51 15.1716 1310.58 15.9032Z"
          fill="#535353"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1347.26 5.09676C1351.81 1.97594 1356.39 0 1362.69 0C1368.99 0 1373.57 1.97594 1378.12 5.09676C1379.19 5.82838 1379.44 7.25703 1378.68 8.28774C1377.92 9.31846 1376.44 9.56092 1375.38 8.8293C1371.38 6.09154 1367.75 4.57726 1362.69 4.57726C1357.63 4.57726 1353.99 6.09154 1350 8.8293C1348.93 9.56092 1347.45 9.31846 1346.7 8.28774C1345.94 7.25703 1346.19 5.82838 1347.26 5.09676Z"
          fill="#535353"
        />
        <path
          d="M1384.18 12.7123C1384.94 11.6815 1386.42 11.4391 1387.49 12.1707C1391.48 14.9085 1395.12 16.4227 1400.17 16.4227C1405.23 16.4227 1408.87 14.9085 1412.86 12.1707C1413.93 11.4391 1415.41 11.6815 1416.16 12.7123C1416.92 13.743 1416.67 15.1716 1415.6 15.9032C1411.05 19.0241 1406.47 21 1400.17 21C1393.87 21 1389.3 19.0241 1384.74 15.9032C1383.68 15.1716 1383.42 13.743 1384.18 12.7123Z"
          fill="#535353"
        />
        <path
          d="M1422.23 5.09676C1426.78 1.97594 1431.36 0 1437.66 0C1443.96 0 1448.54 1.97594 1453.09 5.09676C1454.16 5.82838 1454.41 7.25703 1453.65 8.28774C1452.9 9.31846 1451.42 9.56092 1450.35 8.8293C1446.36 6.09154 1442.72 4.57726 1437.66 4.57726C1432.61 4.57726 1428.97 6.09154 1424.98 8.8293C1423.91 9.56092 1422.43 9.31846 1421.67 8.28774C1420.91 7.25703 1421.16 5.82838 1422.23 5.09676Z"
          fill="#535353"
        />
        <path
          d="M1459.16 12.7123C1459.91 11.6815 1461.39 11.4391 1462.46 12.1707C1466.45 14.9085 1470.09 16.4227 1475.15 16.4227C1480.2 16.4227 1483.84 14.9085 1487.84 12.1707C1488.9 11.4391 1490.38 11.6815 1491.14 12.7123C1491.9 13.743 1491.65 15.1716 1490.58 15.9032C1486.03 19.0241 1481.45 21 1475.15 21C1468.85 21 1464.27 19.0241 1459.72 15.9032C1458.65 15.1716 1458.4 13.743 1459.16 12.7123Z"
          fill="#535353"
        />
        <path
          d="M1497.2 5.09676C1501.75 1.97594 1506.33 0 1512.63 0C1518.93 0 1523.51 1.97594 1528.06 5.09676C1529.13 5.82838 1529.38 7.25703 1528.62 8.28774C1527.87 9.31846 1526.39 9.56092 1525.32 8.8293C1521.33 6.09154 1517.69 4.57726 1512.63 4.57726C1507.58 4.57726 1503.94 6.09155 1499.94 8.8293C1498.88 9.56092 1497.4 9.31846 1496.64 8.28774C1495.88 7.25703 1496.13 5.82838 1497.2 5.09676Z"
          fill="#535353"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1534.12 12.7123C1534.88 11.6815 1536.36 11.4391 1537.42 12.1707C1541.42 14.9085 1545.06 16.4227 1550.11 16.4227C1555.17 16.4227 1558.81 14.9085 1562.8 12.1707C1563.87 11.4391 1565.35 11.6815 1566.1 12.7123C1566.86 13.743 1566.61 15.1716 1565.54 15.9032C1560.99 19.0241 1556.41 21 1550.11 21C1543.81 21 1539.23 19.0241 1534.68 15.9032C1533.61 15.1716 1533.36 13.743 1534.12 12.7123Z"
          fill="#535353"
        />
        <path
          d="M1572.17 5.09676C1576.72 1.97594 1581.3 0 1587.6 0C1593.9 0 1598.48 1.97594 1603.03 5.09676C1604.1 5.82838 1604.35 7.25703 1603.59 8.28774C1602.84 9.31846 1601.36 9.56092 1600.29 8.8293C1596.3 6.09154 1592.66 4.57726 1587.6 4.57726C1582.54 4.57726 1578.91 6.09154 1574.91 8.8293C1573.85 9.56092 1572.37 9.31846 1571.61 8.28774C1570.85 7.25703 1571.1 5.82838 1572.17 5.09676Z"
          fill="#535353"
        />
        <path
          d="M1609.09 12.7123C1609.85 11.6815 1611.33 11.4391 1612.4 12.1707C1616.39 14.9085 1620.03 16.4227 1625.09 16.4227C1630.14 16.4227 1633.78 14.9085 1637.77 12.1707C1638.84 11.4391 1640.32 11.6815 1641.08 12.7123C1641.84 13.743 1641.58 15.1716 1640.52 15.9032C1635.96 19.0241 1631.39 21 1625.09 21C1618.79 21 1614.21 19.0241 1609.66 15.9032C1608.59 15.1716 1608.34 13.743 1609.09 12.7123Z"
          fill="#535353"
        />
        <path
          d="M1647.14 5.09676C1651.69 1.97594 1656.27 0 1662.57 0C1668.87 0 1673.45 1.97594 1678 5.09676C1679.07 5.82838 1679.32 7.25703 1678.56 8.28774C1677.8 9.31846 1676.33 9.56092 1675.26 8.8293C1671.26 6.09154 1667.63 4.57726 1662.57 4.57726C1657.51 4.57726 1653.88 6.09155 1649.88 8.8293C1648.82 9.56092 1647.34 9.31846 1646.58 8.28774C1645.82 7.25703 1646.07 5.82838 1647.14 5.09676Z"
          fill="#535353"
        />
      </svg>
    </div>
  );
}

export default React.memo(ChartSeparator);
