import React from "react";
import { useDispatch } from "react-redux";
import { openSidenav } from "../../../store/sidenav-slice";
import { ShowMenuIcon } from "../../UI/Menu/ShowMenuIcon";
import "./Header.scss";
import { useParams } from "react-router-dom";
import { useGetReportDataByIdQuery } from "../../../services/reports";

export const Header = () => {
  const params = useParams();

  const {
    data: dashboardData,
  } = useGetReportDataByIdQuery(params.id);

  const dispatch = useDispatch();

  const date = new Date(
    dashboardData?.reportData?.reportingDate
  ).toLocaleString("en-US", {
    day: "2-digit",
    month: "short",
    year: "numeric",
    timeZone: "UTC",
  });

  return (
    <div className="header-container">
      <div className="logo-container">
        <span className="title desktop">Know Your Numbers{"\u2122"}</span>
        <span className="title mobile">KYN{"\u2122"}</span>
      </div>
      <div className="info-container">
        <div className="date-info">
          <div className="date">
            <span>{date}</span>
          </div>
        </div>
        <div onClick={() => dispatch(openSidenav())} className="menu">
          <ShowMenuIcon />
        </div>
      </div>
    </div>
  );
};
