import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeSidenav } from "../../../store/sidenav-slice";
import "./Sidenav.scss";
import { useNavigate, useParams } from "react-router-dom";
import SidenavSeparator from "../../UI/Separator/SidenavSeparator";
import { CloseMenuIcon } from "../../UI/Menu/CloseMenuIcon";

export const Sidenav = (props) => {
  const historyData = useSelector((state) => state.sidenav.historyData);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const navigateToReportHandler = (id) => {
    dispatch(closeSidenav());
    navigate(`/${id}`);
  };

  const navigateToSupportUrlHandler = () => {
    dispatch(closeSidenav());
  };

  const navigateToHomeUrlHandler = () => {
    dispatch(closeSidenav());
  };

  return (
    <>
      {props.isOpen && (
        <div
          className={`sidenav-container ${props.isOpen ? "visible" : "hidden"}`}
        >
          <div className="sidenav-inner-container">
            <div className="content">
              <div className="header">
                <div className="sidenav-header-container">
                  <span className="title">select other reports</span>
                  <CloseMenuIcon onClick={() => dispatch(closeSidenav())} />
                </div>
              </div>
              <div className="links">
                <div className="links-container">
                  <div className="wrapper">
                    <span
                      className="url"
                      onClick={() => navigateToSupportUrlHandler()}
                    >
                      support
                    </span>
                    <SidenavSeparator />
                    {historyData?.map((history) => {
                      return (
                        <React.Fragment key={history.key}>
                          <span className="key">{history.key}</span>
                          {history?.value.map((item) => {
                            let activeItem = item?.tokenId === params?.id;
                            return (
                              <div
                                onClick={() =>
                                  navigateToReportHandler(item?.tokenId)
                                }
                                className={`link ${
                                  activeItem ? "active-link" : ""
                                }`}
                                key={item?.tokenId}
                              >
                                <div className="week">
                                  <span>{item?.createdOn}</span>
                                </div>
                                <span className="score">{`${item?.scores}`}</span>
                              </div>
                            );
                          })}
                        </React.Fragment>
                      );
                    })}
                    <SidenavSeparator />
                    <span
                      className="url"
                      onClick={() => navigateToHomeUrlHandler()}
                    >
                      @tallywell
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
